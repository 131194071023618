const emitter = useEmitter()

export class Analytics {
  static enabled = true

  static events = {
    track: 'Analytics::track',
  }

  static enable() {
    this.enabled = true
  }

  static disable() {
    this.enabled = false
  }

  static track(event: TrackEventOptions) {
    if (!this.enabled) {
      return
    }
    emitter.emit(this.events.track, event)
  }

  static handle(handler: (data: TrackEventOptions) => void) {
    if (!this.enabled) {
      return
    }
    emitter.on(this.events.track, handler)
  }
}

export type TrackEventOptions = {
  event: string
  category?: any
  action?: any
  label?: any
  value?: any
  [key: string]: any
}
